import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d31c32dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center justify-content-center m-5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_link = _resolveComponent("b-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_b_link, {
      href: "https://twitter.com/MemphisLions",
      target: "_blank",
      rel: "noopener noreferrer"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "rc-logo m-3",
          src: require('@/assets/twitter-t.png'),
          alt: "Twitter"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createVNode(_component_b_link, {
      href: "https://rightclickable.com",
      target: "_blank",
      rel: "noopener noreferrer"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "rc-logo m-3",
          src: require('@/assets/rc-black-burst.png'),
          alt: "RightClickable.com"
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }),
    _createVNode(_component_b_link, {
      href: "https://discord.gg/MemphisLionsClub",
      target: "_blank",
      rel: "noopener noreferrer"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "rc-logo m-3",
          src: require('@/assets/discord.png'),
          alt: "Discord"
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    })
  ]))
}